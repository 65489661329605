<template>
    <div class="movement">
        <Navbar></Navbar>
        <div class="movement_primary" :class="{m_movement:$store.state.screenType}">
            <div class="swiper">
                <SwiperCom :list="banners" v-if="banners.length"></SwiperCom>
            </div>
            <div class="subject" ref="content">
                <div class="subject_title">
                    <span>{{this.$store.state.languageIndex==2?$t('Nav[3].name').toUpperCase():$t('Nav[4].name').toUpperCase() }}</span>
                    <span>&nbsp; >&nbsp;  </span>
                    <span v-show="this.$route.query.type==='企业动态'">{{ $t('Nav[4].menuList[0]').toUpperCase() }}</span>
                    <span v-show="this.$route.query.type==='赛事动态'">{{ $t('Nav[4].menuList[1]').toUpperCase() }}</span>
                </div>
                <!--<div class="subject_time">-->
                <!--    <div class="subject_time_time" @click="flag=!flag">-->
                <!--        {{ time }}-->
                <!--    </div>-->
                <!--    <div class="subject_time_search">-->
                <!--        <input type="text" :placeholder="key">-->
                <!--        <div class="search" @click="search"></div>-->
                <!--    </div>-->
                <!--    <Timing v-show="flag" @show="changeFlag" @call="callFlag"></Timing>-->
                <!--</div>-->
                <div class="subject_items">
                    <div class="now" v-for="(value, index) in list" :key="index" @click="jumpContent(value.newsId)">
                        <div class="now_show">
                            <img :src="imgUrl(baseUrl,value.coverUrl)" alt="">
                        </div>
                        
                        <div class="now_content">
                            <div class="now_content_time">
                                <p>{{ $getTime(value.createTime).day }}</p>
                                <span>{{ $getTime(value.createTime).year }}/{{ $getTime(value.createTime).month }}</span>
                            </div>
                            <div class="now_content_title">
                                <p>
                                    {{ value.newsTitle }}
                                </p>
                            </div>
                            <div class="now_content_text">
                                <p>
                                    {{ value.newsDes }}
                                </p>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>
        <Footers></Footers>
        <top></top>
    </div>
</template>

<script>
import Navbar from '../../components/Nav/Navbar'
import Footers from '../../components/Footer/Footers'
import Timing from '../Spread/MoveMentMoudle/Timing'
import { imgUrl } from "@/utils/urlutils"
import { mapState } from 'vuex'
import SwiperCom from '../../components/SwiperCom'

export default {
    name: "Movement",
    components: {
        Navbar,
        Footers,
        Timing,
        SwiperCom,
    },
    data () {
        return {
            index: 0,//
            list: [],//页面对应的请求数据的索引
            size: 9,//大小
            num: 1,//页数
            timeType: true,
            meta: {},
            mainIndex: 0,//主体索引
            banners:[],//轮播图数据
            navIndex:3  //导航顺序
        }
    },
    methods: {
        imgUrl,
        // 懒加载
        handleScroll (event) {
            //获取浏览器高度
            const windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            //获取滚动高度
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            //获取页面高度
            const height = document.documentElement.scrollHeight || document.body.scrollHeight;
            if (windowHeight + scrollTop > height - 500) {
                if (this.timeType === true) {
                    
                    this.num++;
                    console.log("向下滑动+1=" + this.num)
                    this.timeType = false
                    this.getAllList()

                    setTimeout(() => {
                        this.timeType = true;
                    }, 500);
                }
            }

        },
        //获取数据
        getAllList () {
            // TODO: 这里置底后再请求后端会一直返回最后的一组数据，可以过滤一下或者加个标识不再请求
            this.$http.getNewList(this.$store.state.languageIndex, this.index, this.num, this.size).then(res => {
                const newsIdList = this.list.map(item => item.newsId);
                res.data.rows.forEach(item => {
                    if (!newsIdList.includes(item.newsId)) {
                        this.list.push(item);
                    }
                });
                // this.list.push(...res.data.rows)
            })
        },
        //获取英文数据
        getEnList(){
            this.num=1
            this.$http.getNewList(this.$store.state.languageIndex, this.index, this.num, this.size).then(res => {
                //console.log(res)
                //console.log(res.data.rows)
                this.list=res.data.rows
            })
        },
        //跳转到内容页面
        jumpContent (id) {

            this.RouteState = false;
            this.$nextTick(()=>{
                this.RouteState = true;
            });
            console.log("点击:"+id);
            // this.$router.push({
            //     path: '/content',
            //     query: {
            //         id: id,
            //         date:new Date().getTime()
            //     }
            // })
            this.$router.push({
            path: "/content",
            query: {
            id: id,
            type: this.index,
            date:new Date().getTime()
            }
        })

        },


        // reload(){
        //     this.RouteState = false;
        //     this.$nextTick(()=>{
        //         this.RouteState = true;
        //     });

        //     this.$router.push({
        //     　　name: 'doclist',
        //     　　query: {
        //             keywords:this.searchText
        //     　　}
        //     });`
        // },



        //获取title标题内容
        getList () {
            this.$http.getPage(this.$store.state.languageIndex, this.mainIndex).then(res => {
                console.log(res)
                this.meta = res.data.data
                this.banners= res.data.data.banners
                this.$getMedia()
            })
        }
    },
    activated(){
        this.index = this.$route.query.type === '企业动态' ? 51 : 52
        this.mainIndex = this.$route.query.type === '企业动态' ? 10 : 11
        this.navIndex = this.$store.state.languageIndex == 1?3:4
        this.list = []
        console.log("getList==="+this.index)
        this.getList();
        this.getAllList()
        console.log("this.$route.languageIndex="+this.$store.state.languageIndex)
    },
    created () {
        this.index = this.$route.query.type === '企业动态' ? 51 : 52
        this.mainIndex = this.$route.query.type === '企业动态' ? 10 : 11
        this.getList()
        this.getAllList()
    },
    mounted () {
        // 注册 滚动事件
        window.addEventListener("scroll", this.handleScroll, true);
    },
    destroyed () {
        //注销 滚动事件
        window.removeEventListener("scroll", this.throttleFun, true);
    },
    watch: {
        '$store.state.language' (newValue, oldValue) {
            this.getEnList()
            this.getList()
            if (newValue) {
                this.time = 'DATA'
                this.key = 'KEYWORD'
            } else {
                this.time = '日期'
                this.key = '关键字'
            }
        }
    },
    computed: {
        ...mapState(['baseUrl'])
    }
}
</script>


<style scoped lang="scss">

/*-- -------Movement---------- --*/
.movement {
    &_primary {
        padding-top: 6.25rem;
        max-width: 1920px;
        margin: 0 auto;

        .swiper {
            width: 100%;
            // height: 37.6875rem;
            background-color: #F5F5F3;
            ::v-deep .Swiper{
                height: 100%;
                .swiper-container{
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .subject {
            padding: 0 7.5rem;
            box-sizing: border-box;

            &_title {
                font-size: 1.5rem;
                line-height: 5.3125rem;
            }

            &_time {
                display: flex;
                position: relative;

                &_time {
                    width: 33rem;
                    height: 4.71875rem;
                    background-color: #FBFBFB;
                    margin-right: 0.9375rem;
                    padding: 0 1.8125rem 0 2.5rem;
                    line-height: 4.71875rem;
                    font-size: 1.125rem;
                    color: #898989;
                    position: relative;

                }

                &_search {
                    width: 33rem;
                    height: 4.71875rem;
                    background-color: #FBFBFB;
                    margin-right: 0.9375rem;
                    padding: 0 1.8125rem 0 2.5rem;
                    line-height: 4.71875rem;
                    font-size: 1.125rem;
                    color: #898989;
                    position: relative;

                    input {
                        border: 0;
                        outline: none;
                        background-color: #FBFBFB;
                        font-size: 1.125rem;
                        width: 60%;
                    }

                    .search {
                        width: 1.875rem;
                        height: 1.875rem;
                        background-image: url('../../assets/search.png');
                        background-size: 100% auto;
                        position: absolute;
                        top: calc(50% - 0.9375rem);
                        right: 2.5rem;
                    }
                }
            }

            &_items {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                //margin: 3.875rem 0;

                .now {
                    width: 32%;
                    //width: 33.0625rem;
                    height: 45.375rem;
                    background-color: #FBFBFB;
                    margin-bottom: 1.625rem;
                    cursor: pointer;
                    &_show {
                        width: 100%;
                        height: 23.3125rem;
                        text-align: center;
                        overflow: hidden;

                        img {
                            width: 100%;
                            position: relative;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }

                    &_content {
                        width: 100%;
                        height: 22.0625rem;
                        padding: 2.75rem 2.875rem;
                        box-sizing: border-box;

                        &_time {
                            font-family: 'AlibabaPuHuiTiL';

                            p {
                                font-size: 3.125rem;
                                line-height: 2.625rem;
                            }

                            span {
                                border-bottom: 0.125rem solid #ED121B;
                            }
                        }

                        &_title {
                            font-size: 1.625rem;
                            line-height: 2.25rem;
                            font-family: 'AlibabaPuHuiTiM';
                            margin-top: 0.375rem;
                        }

                        &_text {
                            font-size: 1.25rem;
                            line-height: 1.875rem;
                            font-family: 'AlibabaPuHuiTiL';
                            margin-top: 0.625rem;
                        }
                    }
                }

                &:after {
                    content: '';
                    width: 33.0625rem;
                }
            }
        }
    }
}

.m_movement {
    .subject {
        padding: 0 3.4375rem;

        .subject_title {
            padding: 3rem 0;
        }

        .subject_time {
            .subject_time_time {
                display: none;
            }

            .subject_time_search {
                margin: 0 auto;
            }
        }

        .subject_items {
            .now {
                width: 100%;
                height: auto;
                margin-bottom: 2.625rem;

                &_show {
                    width: 100%;
                    height: 30rem;

                    img {
                        width: 100%;
                    }
                }

                &_content {
                    padding: 3.4375rem;
                    height: auto;
                }
            }
        }
    }
}
</style>
