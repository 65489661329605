<template>
    <div class="timing">
        <div class="select">
            <button @click="add"></button>
            <button @click="minus"></button>
            <div class="select_center">
                <select name="" v-model="time.year">
                    <option :value="value" v-for="(value,index) in dateList" :key="index">{{ value }}</option>
                </select>
                <select name="" v-model="time.month">
                    <option :value="value" v-for="value in 12">{{ value }}月</option>
                </select>
            </div>
        </div>
        <div class="time">
            <span v-for="(value,index) in dateList" :key="index" @click="time.year=value">{{ value }}</span>
            <div class="btn" :class="{active:$store.state.language}">
                <button @click="abolish">{{$t('Media.cancel')}}</button>
                <button @click="commit">{{$t('Media.confirm')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Timing",
    components: {},
    data () {
        return {
            time: {
                year: 2022,
                month: 12
            },
            dateList: [2022, 2021, 2020, 2019, 2018, 2017, 2016]
        }
    },
    methods: {
        //月份增加
        add () {
            if (this.time.month !== 12 || this.time.year !== 2022) {
                this.time.month++
                if (this.time.month === 13) {
                    this.time.month = 1
                    this.time.year++
                }
            }
        },
        //月份减少
        minus () {
            this.time.month--
            if (this.time.month === 0) {
                this.time.month = 12
                this.time.year--
            }
        },
        //确定按钮
        commit(){
            console.log('点击')
            this.$emit('show',this.time)
        },
        //取消按钮
        abolish(){
            this.$emit('call')
        }
    }

}
</script>


<style scoped lang="scss">

/*-- -------Timing---------- --*/
.timing {
    position: absolute;
    left: 0;
    top: 4.71875rem;
    width:37.3rem;
    background-color: #FBFBFB;
    font-family: 'AlibabaPuHuiTiL';
    z-index: 100;

    .select {
        width: 100%;
        height: 4.6875rem;
        position: relative;

        &_center {
            width: 13rem;
            height: 4.6875rem;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            text-align: center;

            select {
                width: 5rem;
                outline: none;
                font-size: 1.125rem;
                color: #282827;
                margin-top: 1.25rem;
                font-family: 'AlibabaPuHuiTiL';
                &:first-child {
                    width: 6.125rem;
                    margin-right: 0.5rem;
                }
            }
        }

        button {
            position: absolute;
            top: calc(50% - 1rem);
            right: 1.875rem;
            width: 2rem;
            height: 2rem;
            background: #FBFBFB url('../../../assets/m_05.png') no-repeat center center;
            background-size: 0.625rem 1rem;
            border: 0.0625rem solid #BFBFBF;

            &:first-child {
                transform: rotateZ(180deg);
                left: 1.875rem;
            }
        }
    }

    .time {
        padding: 2.375rem 5.625rem;
        box-sizing: border-box;

        span {
            width: 25%;
            display: inline-block;
            font-size: 1.125rem;
            line-height: 4.3125rem;
            cursor: pointer;
        }

        .btn {
            line-height: 2rem;
            width: 13.75rem;
            display: flex;
            justify-content: space-between;
            margin: 0 auto;

            button {
                width: 6.1875rem;
                height: 2rem;
                background-color: transparent;
                border: 0.0625rem solid #BFBFBF;
                font-family: 'AlibabaPuHuiTiL';
                letter-spacing: 0.375rem;
                font-size: 1.125rem;
            }
        }
        .active{
           button{
               letter-spacing: 0!important;
           }
        }
    }
}
</style>
